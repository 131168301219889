<template>
    <el-dialog :title="`文件${title}`" width="40%" :close-on-click-modal="false" :visible.sync="visible">
        <div class="f_w m_b2" v-if="method == 'move'">您将移动文件：</div>
        <div class="f_w m_b2" v-if="method == 'edit'">您将正在修改以下文件的著录项信息：</div>
        <div class="m_l1 m_b2"><span v-for="(item, index) in list.slice(0,4)" :key="index"><span v-if="sourceType == 'reorganize'">“{{item.culturalName}}”</span><span v-if="sourceType == 'cultural'">“{{item.culturalName}}”</span>{{index < 3 && index > 1 ? '、':''}}</span><span v-if="list.length > 5">等</span>{{list.length}}个文件</div>
        <el-form size="small" :model="modelType" :ref="formType" label-width="120px" v-loading="loading" :element-loading-text="txt"
                 element-loading-spinner="el-icon-loading">
<!--            <el-form-item v-if="method == 'move'" label="目标为：" prop="quanzongNo" :rules="[{ required: true, message: '请选择', trigger: 'change' }]">-->
<!--                <el-select v-model="moveForm.quanzongNo" style="width:100%" @change="manaList" placeholder="请选择">-->
<!--                    <el-option-->
<!--                            v-for="item in exhibitionList"-->
<!--                            :key="item.id"-->
<!--                            :label="item.exhibitionName"-->
<!--                            :value="item.id">-->
<!--                    </el-option>-->
<!--                </el-select>-->
<!--            </el-form-item>-->
            <el-form-item v-if="method == 'move'" label="移动展览为：" prop="exhibitionId" :rules="[{ required: true, message: '请选择移动展览', trigger: 'change' }]">
                <el-select v-model="moveForm.exhibitionId" filterable style="width:100%" placeholder="请选择移动展览">
                    <el-option
                            v-for="item in exhibitionList"
                            :key="item.id"
                            :label="item.exhibitionName"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item v-if="method == 'edit'" label="著录项：" prop="catalog" :rules="[{ required: true, message: '请选择著录项', trigger: 'change' }]">
                <el-select v-model="editForm.catalog" filterable style="width:100%" placeholder="请选择" @change="catalogListChange">
                    <el-option v-if="item.beEdit==0"
                            v-for="(item,index) in catalogList"
                            :key="index"
                            :label="item.recordName"
                            :value="item.fieldEnname">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item v-if="method == 'edit'" label="值：" prop="val" :rules="[{ required: true, message: '请输入', trigger: 'change' }]">
                <el-input v-if="configVal.detailsShowType=='0'" v-model.trim="editForm.val" maxlength="100"></el-input>
                <el-date-picker v-if="configVal.detailsShowType=='2'" v-model="editForm.val" type="year" style="width:100%"
                                placeholder="选择年度" value-format="yyyy"></el-date-picker>
                <el-date-picker v-if="configVal.detailsShowType=='3'" v-model="editForm.val" type="datetime" style="width:100%"
                                placeholder="选择日期" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="visible = false">取消</el-button>
            <el-button size="small" type="primary" @click="doSubmit()" v-noMoreClick>保存</el-button>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        data() {
            return {
                title: '',
                method: '',
                sourceType: '',
                visible: false,
                loading: false,
                list: [],
                catalogueList: [], //目录
                exhibitionList: [], //展览
                formType: '',
                modelType: {},
                moveForm: {
                    exhibitionId:"",
                },
                editForm: {
                    catalog:"",
                    val:"",
                },
                catalogList: [],
                log:{
                    operModular:"3",//模块
                    operType:"5",//类型：移动
                    operTerm:"",//内容
                    operSystem:"1",//结果
                },
                fileVersionHistory:{
                    fileId:"",
                    primaryId:"",//移动前的所属展览id
                    moveFlag:1,//移动
                    newPrimaryId:"",//移动后的所属展览id
                },
                configVal:{},
                txt:"拼命加载中"
            }
        },
        methods: {
            init(method, row, type,config) { // reorganize整编、cultural文物
                this.moveForm.exhibitionId = ""
                this.modelType={};
                this.editForm.catalog = ""
                let that = this;
                this.method = method
                this.sourceType = type
                this.list = row
                console.log(this.list);
                if (method === 'move') {
                    this.title = '移动'
                    this.formType = 'moveForm'
                    // this.moveForm.exhibitionId=row[0].exhibitionId
                    this.exhibitionInAll(row[0].exhibitionId);
                    this.modelType = this.moveForm
                } else if (method === 'edit') {
                    this.title = '修改'
                    this.formType = 'editForm'
                    this.modelType = this.editForm
                    this.catalogList=JSON.parse(JSON.stringify(config))
                    this.catalogList.forEach(function (v) {
                        v.fieldEnname=that.getKebabCase(v.fieldEnname)
                    })
                }
                this.visible = true
                // this.exhibitionInAll(); //所有展览
                this.$nextTick(() => {
                    this.$refs[this.formType].resetFields()
                })
                console.log(this.moveForm.exhibitionId);
            },
            getKebabCase(str) {
                return str.replace( /[A-Z]/g, function( i ) {
                    return '_' + i.toLowerCase();
                })
            },
            exhibitionInAll(quanzongNo){
                this.$axios(this.api.common.queryExhibition2, {}, 'get').then(data => {
                    if (data.status) {
                        let that = this;
                        this.exhibitionList = data.data;
                        that.exhibitionList.forEach(function (v,i) {
                            if(v.id==quanzongNo){
                                that.exhibitionList.splice(i,1)
                            }
                        })
                    }
                })
            },
            manaList(uploadTarget){
                console.log(uploadTarget);
                this.$axios(this.api.mana.selectSysDirectory, {
                    "exhibitionId":this.moveForm.quanzongNo
                }, 'get').then(data => {
                    if (data.status) {
                        let that = this;
                        this.catalogueList = data.data;
                        console.log(this.catalogueList);
                        that.catalogueList.forEach(function (v,i) {
                            console.log(v);
                            if(v.id==uploadTarget){
                                console.log(v);
                                that.catalogueList.splice(i,1)
                            }

                        })
                    }
                })
            },
            //新增日志
            logSave(){
                let that = this;
                let batchList = []
                this.list.forEach(function (v) {
                    // console.log(v.fileName);
                    let log = JSON.parse(JSON.stringify(that.log))
                    log.operTerm = v.culturalName
                    console.log(log.operTerm);
                    batchList.push(log)
                })
                console.log(batchList);
                console.log("批量删除");
                this.$axios(this.api.common.batchInsertLogin,batchList,'post').then(data => {
                    // console.log("日志：",data);
                    // if(data.status){
                    // this.getFileList()
                    // }
                })
            },
            // 表单提交
            doSubmit() {
                this.$refs[this.formType].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        if (this.method === 'move') {
                            this.txt="正在重新排列顺序号，请稍后~"
                            // 保存移动
                            let ids = this.list.map(item => {
                                return item.id
                            }).join(',')
                            let oldExhibitionId = ""
                            this.list.forEach(function (v) {
                                oldExhibitionId = v.exhibitionId
                            })
                            this.$axios(this.api.common.batchMobile, {
                                'ids': ids,
                                "exhibitionId":this.moveForm.exhibitionId,
                                "uploadTarget":oldExhibitionId,
                            }, 'put').then(data => {
                                this.loading = false
                                this.log.operType = "5"
                                if (data && data.status) {
                                    this.$message.success("顺序号排列完成，"+data.msg)
                                    this.log.operSystem = '0'
                                    this.visible = false
                                    this.$emit('refreshDataList')
                                    this.batchInsert();
                                }else{
                                    this.$message.error(data.msg)
                                    this.log.operSystem = '1'
                                }
                                // this.logSave();
                            })
                        } else if (this.method === 'edit') {
                            this.txt="拼命加载中"
                            let ids = this.list.map(item => {
                                return item.id
                            }).join(',')
                            // 保存修改
                            this.$axios(this.api.common.culturalRelicsBatchUpdate, {
                                'ids': ids,
                                "fieldName1":this.editForm.catalog,
                                "fieldName2":this.editForm.val,
                            }, 'put').then(data => {
                                this.loading = false
                                this.log.operType = "15"
                                if (data && data.status) {
                                    this.$message.success(data.msg)
                                    this.log.operSystem = '0'
                                    this.visible = false
                                    this.$emit('refreshDataList')
                                }else{
                                    this.$message.error(data.msg)
                                    this.log.operSystem = '1'
                                }
                                // this.logSave();
                            })
                        }

                    }
                })
            },
            catalogListChange(val){
                let that = this;
                this.catalogList.forEach(function (v) {
                    if(val==v.fieldEnname){
                        that.configVal=v
                    }
                })
                // console.log(that.configVal);
            },
            //新增版本
            batchInsert(){
                let that = this;
                let batchList = []
                this.list.forEach(function (v) {
                    // console.log(v.fileName);
                    let fileVersionHistory = JSON.parse(JSON.stringify(that.fileVersionHistory))
                    fileVersionHistory.fileId = v.id
                    fileVersionHistory.primaryId = v.exhibitionId
                    fileVersionHistory.newPrimaryId = that.moveForm.exhibitionId
                    console.log(fileVersionHistory);
                    batchList.push(fileVersionHistory)
                })
                this.$axios(this.api.common.culturalVersionHistoryBatchInsert,batchList,'post').then(data => {
                    // console.log("日志：",data);
                    // if(data.status){
                    // this.getFileList()
                    // }
                })
            },
        },
    }
</script>

<style scoped>

</style>
